import { render, staticRenderFns } from "./NetballVictoria.vue?vue&type=template&id=1c834866&scoped=true&"
import script from "./NetballVictoria.vue?vue&type=script&lang=js&"
export * from "./NetballVictoria.vue?vue&type=script&lang=js&"
import style0 from "./NetballVictoria.vue?vue&type=style&index=0&id=1c834866&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c834866",
  null
  
)

component.options.__file = "NetballVictoria.vue"
export default component.exports