<template>
  <div class="work-page netball-victoria">
  	<!-- Header -->
  	<section class="header">

      <div>
        <img src="/static/images/NV_1.png"/>
        <!-- <img v-lazy="'/static/images/NV_2.jpg'"/> -->
      </div>
    
  		<div class="meta" style="margin-top: -6.5%;">
  			<p class="client">Netball VIC</p>
  			<a class="project">VIXENS 4 LIFE</a>
  		</div>
  		<div class="copy container">
  			<div class="col-1">
  				<h1>Not just a club, a sisterhood.</h1>
  			</div>
  			<div class="col-2">
  				<p>They are a tight knit crew, more like a girl gang than a team. Once you’re a Vixen, you’re a Vixen for life.</p>
  			</div>
  		</div>
  	</section>

    <div class="spacer-lg"></div>

    <img src="/static/images/NV_2.jpg" style="width: 80%;"/>

    <div class="two-col no-negative-top-mobile" style="margin-top: -8%; align-items: flex-start;justify-content: space-between;">
      <div class="col" style="width: 49%;">
        <img src="/static/images/NV_4.png" style="width: 45%; margin-right: 10%; float: right;"/>
      </div>
      <div class="col" style="width: 49%">
        <img src="/static/images/NV_5.jpg" style=""/>
      </div>
    </div>

    <div class="spacer-lg"></div>

    <div style="width: 20%; margin-bottom: -4%; z-index: 2; margin-top: -22%; margin-left: 2%; position: relative;"><img src="/static/images/NV_6.png"/></div>

    <div style="z-index: 1;"><img src="/static/images/NV_7.jpg"/></div>

    <div class="spacer-lg"></div>


    <div class="two-col" style="justify-content: center;">
      <div class="col" style="width: 50%;">
        <img src="/static/images/NV_8.jpg"/>
      </div>
      <div class="col" style="width: 35%; margin-left: -40px; z-index: 2;">
        <img src="/static/images/NV_9.jpg"/>
      </div>
    </div>

    <div class="spacer-lg"></div>

    <div class="standout-copy" style="width: 87%; text-align: left;">
      Our iconography was inspired by girl gangs from pop culture.
    </div>

    <div class="spacer-lg"></div>

    <div class="two-col" style="justify-content: space-between;">
      <div class="col" style="width: 48%">
        <img src="/static/images/NV_10.jpg"/>
      </div>
      <div class="col" style="width: 55%; margin-right: -7%; margin-bottom: -45%;">
        <img src="/static/images/NV_11.jpg"/>
      </div>
    </div>

    <div class="spacer-lg"></div>

    <div style="width: 24%; margin-bottom: -9%; z-index: 2; margin-top: 3%; margin-left: 2%; position: relative;"><img src="/static/images/NV_13.png"/></div>

    <div style="z-index: 1; width: 80%" class="mobile-full"><img src="/static/images/NV_12.jpg"/></div>

    <div class="spacer-lg"></div>

    <img src="/static/images/NV_14.jpg" class="mobile-full" style="width: 80%; float: right"/>

    <div class="clear"></div>

    <div class="footer">
      <div class="prev">
        <router-link to="/zerafa">
          <img src="/static/images/prev.svg"/>
        </router-link>
      </div>
      <div class="footer-contact">
        <a href="mailto:grant@bloodutd.com,david@bloodutd.com">Drop us a line</a>
        <span>&copy; {{date}} by Blood UTD</span>
      </div>
      <div class="next">
        <router-link to="/ca-watch-me-2">
          <img src="/static/images/next.svg"/>
        </router-link>
      </div>
    </div>

  </div>
</template>


<script>
export default {
  name: 'netball-victoria',
  data() {
    return {
      date: new Date().getFullYear(),
    }
  }
}
</script>

<style lang="scss" scoped>

.netball-victoria {

  .no-negative-top-mobile {
    @media(max-width: 680px) {
      
      .col:first-child {
         width: 39% !important;
         z-index: 2 !important;
        img {
          width: 60% !important;
          margin-right: -10% !important;
          margin-top: 10% !important;
        }
      }

      .col:last-child {
        width: 59% !important;
      } 
    }
  }

}

</style>
